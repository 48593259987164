import React, { ReactElement, useEffect } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';

import useAdminUsers from '../../../core/hooks/useAdminUsers';
import AppLoading from '../../AppLoading';
import AppTable from '../../AppTable';
import styles from './AdminUsers.module.less';
import CopyToClipboardText from '../../CopyToClipboardText';

interface Props extends CcxComponentProps {}

function AdminUsers({ testId = 'AdminUsers' }: Props): ReactElement {
    const { users, refresh, loading } = useAdminUsers();

    useEffect(() => {
        const AUTO_REFRESH_INTERVAL = 20000;
        const interval = setInterval(async () => {
            refresh && (await refresh());
        }, AUTO_REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, []);

    const tableColumns = [
        {
            title: 'ID',
            key: 'id',
            render: (value: string, record: any) => {
                return (
                    <CopyToClipboardText text={record.id} hoverable={false} />
                );
            },
        },
        {
            title: 'Name',
            key: 'name',
            render: (value: string, record: any) => {
                return `${record.firstName} ${record.lastName}`;
            },
        },
        {
            title: 'Login',
            dataIndex: 'login',
            key: 'login',
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
    ];

    return loading ? (
        <AppLoading />
    ) : (
        <div className={styles.AdminUsers}>
            <AppTable
                columns={tableColumns}
                testId={`${testId}Table`}
                data={users || []}
                rowKey="id"
                expandable={false}
                pagination={{
                    hideOnSinglePage: true,
                    pageSize: 50,
                }}
            />
        </div>
    );
}

export default AdminUsers;
