import AppConfirmDialog from '../../AppConfirmDialog';
import styles from './DataStoreDeleteButton.module.less';

import React from 'react';

export type DatastoreDeleteButtonProps = {
    confirmPhrase?: string;
    onConfirmDelete?: () => void;
};
export default function DataStoreDeleteButton({
    confirmPhrase = 'delete',
    onConfirmDelete,
}: DatastoreDeleteButtonProps) {
    return (
        <AppConfirmDialog
            critical={true}
            label="Delete"
            title={
                <strong className={styles.DataStoresDeleteTitle}>
                    Delete a datastore
                </strong>
            }
            alertTitle={
                <span className={styles.DataStoresDeleteSubTitle}>
                    You’re going to delete a datastore
                </span>
            }
            onOk={onConfirmDelete}
            content="The database, load balancers and all backup files will be completely removed. Database users and data will be lost. This action cannot be reverted."
            confirmPhrase={confirmPhrase}
            confirmHintText={`Type "${confirmPhrase}" into the field to confirm`}
            confirmPlaceholder={`Click here to type`}
        />
    );
}
