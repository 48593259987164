import DataStoreStatus from './DataStoreStatus';
import FriendlyDateTime from './FriendlyDateTime';

export type DatastoreClassProps = {
    id: string;
    name: string;
    status: string;
    user_login: string;
    created_at: string;
    type: string;
    size: number;
    internal_id: number;
};

export interface DatastoreInterface {
    id: string;
    name: string;
    status: DataStoreStatus;
    userLogin: string;
    createdAt: string;
    type: string;
    size: number;
    internalId: number;
}

export default class Datastore implements DatastoreInterface {
    readonly id: string;
    readonly name: string;
    readonly status: DataStoreStatus;
    readonly userLogin: string;
    readonly createdAt: string;
    readonly type: string;
    readonly size: number;
    readonly internalId: number;

    constructor(props: DatastoreClassProps) {
        this.id = props.id;
        this.name = props.name;
        this.status = new DataStoreStatus({
            cluster_status: props.status,
            cluster_status_text: props.status,
        });
        this.userLogin = props.user_login;
        this.createdAt = new FriendlyDateTime({
            date: props.created_at,
        }).getFriendlyDate();
        this.size = props.size;
        this.type = props.type;
        this.internalId = props.internal_id;
    }

    getDataStoreStatus(): string {
        if (this.status.isStatusOk()) return 'Available';
        if (this.status.isDeleting()) return 'Deleting';
        if (this.status.isDeploying()) return 'Deploying';
        if (this.status.isStatusError()) return 'Failed';
        return 'Unknown';
    }
}
