import { useEffect, useState } from 'react';
import AdminService from '../../services/AdminService';
import Datastore from '../../types/Datastore';

export default function useAdminDataStores() {
    const [loading, setLoading] = useState<boolean>(true);
    const [updating, setUpdating] = useState<boolean>(false);
    const [dataStores, setDataStores] = useState<Datastore[] | undefined>(
        undefined
    );
    const [error, setError] = useState<string | null>(null);

    const load = async () => {
        try {
            const result = await AdminService.getDataStores();
            setDataStores(result);
        } catch (err: any) {
            console.error(err);
            setError(err);
        }
    };

    const refresh = () => {
        const update = async () => {
            setUpdating(true);
            await load();
            setUpdating(false);
        };
        update();
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        if (!dataStores) {
            init();
        } else {
            refresh();
        }
    }, []);

    return { dataStores, error, loading, refresh, updating };
}
