export const DB_VENDOR_MARIADB = 'mariadb';
export const DB_VENDOR_POSTGRESQL = 'pgsql';
export const DB_VENDOR_POSTGRESQL_V2 = 'postgres'; // introduced in provisioning v2
export const DB_VENDOR_PERCONA = 'percona';
export const DB_VENDOR_REDIS = 'redis';
export const DB_VENDOR_MSSQL = 'microsoft';
export const DB_VENDOR_UNKNOWN = '';

export const DB_VENDOR_MARIADB_PRETTY = 'MariaDB';
export const DB_VENDOR_POSTGRESQL_PRETTY = 'PostgreSQL';
export const DB_VENDOR_PERCONA_PRETTY = 'MySQL';
export const DB_VENDOR_REDIS_PRETTY = 'Redis';
export const DB_VENDOR_MSSQL_PRETTY = 'SQL Server';

const MARIADB_ICON = 'vendor/mariadb';
const PERCONA_ICON = 'vendor/mysql';
const POSTGRESQL_ICON = 'vendor/postgresql';
const REDIS_ICON = 'vendor/redis';
const MSSQL_ICON = 'vendor/mssql';
const UNKNOWN_ICON = '';

export enum DatabaseType {
    MYSQL = 'mysql',
    POSTGRES = 'postgres',
    MSSQL = 'mssql',
    REDIS = 'redis',
    UNKNOWN = '',
}

export type DbVendorClassProps = {
    database_vendor: string;
    database_version: string;
    database_endpoint: string;
};

export interface DbVendorInterface {
    dbVendor: string;
    dbVersion: string;
    dbEndpoint: string;
    dbVendorLogo: string;
}

export default class DbVendor implements DbVendorInterface {
    readonly dbVendor: string;
    readonly dbVersion: string;
    readonly dbEndpoint: string;
    readonly dbVendorLogo: string;

    constructor(props: DbVendorClassProps) {
        switch (props.database_vendor) {
            case DB_VENDOR_MARIADB:
                this.dbVendor = DB_VENDOR_MARIADB;
                this.dbVendorLogo = MARIADB_ICON;
                break;
            case DB_VENDOR_POSTGRESQL:
            case DB_VENDOR_POSTGRESQL_V2:
                this.dbVendor = DB_VENDOR_POSTGRESQL;
                this.dbVendorLogo = POSTGRESQL_ICON;
                break;
            case DB_VENDOR_PERCONA:
                this.dbVendor = DB_VENDOR_PERCONA;
                this.dbVendorLogo = PERCONA_ICON;
                break;
            case DB_VENDOR_REDIS:
                this.dbVendor = DB_VENDOR_REDIS;
                this.dbVendorLogo = REDIS_ICON;
                break;
            case DB_VENDOR_MSSQL:
                this.dbVendor = DB_VENDOR_MSSQL;
                this.dbVendorLogo = MSSQL_ICON;
                break;
            default:
                this.dbVendor = DB_VENDOR_UNKNOWN;
                this.dbVendorLogo = UNKNOWN_ICON;
        }

        this.dbVersion = props.database_version;
        this.dbEndpoint = props.database_endpoint;
    }

    getIconName(): string {
        if (this.isMariadb()) {
            return MARIADB_ICON;
        } else if (this.isPostgresql()) {
            return POSTGRESQL_ICON;
        } else if (this.isPercona()) {
            return PERCONA_ICON;
        } else if (this.isRedis()) {
            return REDIS_ICON;
        } else if (this.isMSSQL()) {
            return MSSQL_ICON;
        } else {
            return UNKNOWN_ICON;
        }
    }

    getDatabaseType(): DatabaseType {
        if (this.isMariadb()) {
            return DatabaseType.MYSQL;
        } else if (this.isPostgresql()) {
            return DatabaseType.POSTGRES;
        } else if (this.isPercona()) {
            return DatabaseType.MYSQL;
        } else if (this.isRedis()) {
            return DatabaseType.REDIS;
        } else if (this.isMSSQL()) {
            return DatabaseType.MSSQL;
        } else {
            return DatabaseType.UNKNOWN;
        }
    }

    getVendorName(): string {
        if (this.isMariadb()) {
            return DB_VENDOR_MARIADB_PRETTY;
        } else if (this.isPostgresql()) {
            return DB_VENDOR_POSTGRESQL_PRETTY;
        } else if (this.isPercona()) {
            return DB_VENDOR_PERCONA_PRETTY;
        } else if (this.isRedis()) {
            return DB_VENDOR_REDIS_PRETTY;
        } else if (this.isMSSQL()) {
            return DB_VENDOR_MSSQL_PRETTY;
        } else {
            return '';
        }
    }

    getDbEndpoint(): string {
        return this.dbEndpoint;
    }

    isPostgresql(): boolean {
        return (
            this.dbVendor === DB_VENDOR_POSTGRESQL ||
            this.dbVendor === DB_VENDOR_POSTGRESQL_V2
        );
    }

    isMariadb(): boolean {
        return this.dbVendor === DB_VENDOR_MARIADB;
    }

    isPercona(): boolean {
        return this.dbVendor === DB_VENDOR_PERCONA;
    }

    isRedis(): boolean {
        return this.dbVendor === DB_VENDOR_REDIS;
    }

    isMSSQL(): boolean {
        return this.dbVendor === DB_VENDOR_MSSQL;
    }
}
